<!--
 * ======================================
 * 说明： 公共头部
 * 作者： Silence
 * 文件： header.vue
 * 日期： 2023/7/22 23:27
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="select-logo-layout w-11/12 py-3">
    <img :src="logo" class="mx-auto h-16 w-auto" alt="logo" />
    <h2 class="mt-3 text-center text-xl font-extrabold text-gray-900">
      智慧校园综合素质评价管理系统
    </h2>
    <p class="mt-2 text-center text-sm text-gray-200">
      欢迎登录学生综合素质评价系统
    </p>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      logo: "",
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getSystem();
  },
  methods: {
    getSystem() {
      this.loading = true;
      this.$cloud
        .get("setting/get", { key: "logo" })
        .then((res) => {
          this.loading = false;
          try {
            this.logo = JSON.parse(res).logo;
          } catch (e) {
            this.logo = "";
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.select-logo-layout {
  margin: 1rem auto 0;
  background: url("~@/assets/southeast.jpg") center -2rem no-repeat;
}
</style>
