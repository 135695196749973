<!--
 * ======================================
 * 说明： 移动端主页
 * 作者： Silence
 * 文件： index.vue
 * 日期： 2023/7/21 22:41
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="select-class-layout">
    <mobileHeader />
    <div class="text-right w-11/12 mx-auto">
      <div class="edit-password-btn" @click="handleEditPassword('admin')">
        修改密码
      </div>
    </div>

    <div class="mobile-page-main w-11/12 mx-auto">
      <div
        v-if="classs.length"
        class="flex items-center justify-start flex-wrap last-child"
      >
        <div
          class="flex items-center justify-center class-items"
          v-for="(item, index) in classs"
          :key="index"
          @click="handleToScore(item)"
        >
          <div class="w-full h-full text-['#666666']">
            <div class="shadow flex items-center justify-center bg-white rounded mb-1" style="height: calc(100% - 2rem);">
              <img src="@/assets/class-icon.png" />
            </div>
            {{ item.level
            }}{{
              item.name.length > 1
                ? String(item.name)
                : "0" + String(item.name)
            }}班
          </div>
        </div>
      </div>
      <div v-else class=" rounded overflow-hidden flex items-center justify-center">
          <img src="@/assets/empty.png" alt="" class="empty-image">
      </div>
    </div>
  </div>
</template>
<script>
import mobileHeader from '../components/header.vue'
export default {
  name: "",
  components: {mobileHeader},
  props: {},
  data() {
    return {
      classs: [],
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.handleLoadClasss();
  },
  methods: {
    // 获取当前教师所管理的班级列表
    handleLoadClasss() {
      this.$cloud
        .get("/login/info")
        .then(async (res) => {
          this.info = res;
          let masterRoom = null;
          if (res.masterRoom) {
            this.masterId = res.masterRoom.id;
            masterRoom = {
              id: res.masterRoom.id,
              level: res.masterRoom.level,
              name: res.masterRoom.name,
              count: res.masterRoom.count,
              isMaster: 1,
              subjectId: -1,
              evaluationId: res.masterRoom.evaluationId,
            };
          }
          let subjectRoom = [];
          if (
            res.subjectRoom &&
            typeof res.subjectRoom == "object" &&
            res.subjectRoom.length
          ) {
            res.subjectRoom.forEach((item) => {
              if (
                masterRoom &&
                parseInt(masterRoom.level) === parseInt(item.room.level) &&
                masterRoom.name === item.room.name
              ) {
                return false;
              }
              subjectRoom.push({
                id: item.room.id,
                level: item.room.level,
                name: item.room.name,
                count: item.room.student_ids
                  ? item.room.student_ids.split(",").length
                  : 0,
                isMaster: 0,
                subjectId: item.subject[0]?.id,
                evaluationId: item.subject[0]?.evaluationId,
              });
            });
          }
          if (masterRoom && subjectRoom.length) {
            this.classs = [masterRoom].concat(subjectRoom);
          } else if (masterRoom && !subjectRoom.length) {
            this.classs = [masterRoom];
          } else if (!masterRoom && subjectRoom.length) {
            this.classs = subjectRoom;
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  
    // 进入评分页面
    handleToScore(item) {
  
      let classname = item.level + (item.name.length > 1 ? String(item.name): "0" + String(item.name))
      this.$cloud.go("/mobile/score/index", {
        id: item.id,
        classname:classname,
        isMaster: item.isMaster,
      });
    },

    /**
     *  @actions:  修改密码
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/7/22 22:07
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleEditPassword() {
      this.$cloud.go('/mobile/password/index')
    },
  },
};
</script>
<style lang="scss" scoped>
.empty-image{
  width: 14rem;
  margin-top: 4rem;
}
.select-class-layout {
  width: 100%;
  height: 100vh;
  background-color: #EEF4F9;
  overflow: hidden;
  overflow-y: auto;
  .edit-password-btn {
    margin: 1rem auto;
    display: inline-block;
    padding: 0.2rem 1rem;
    border: solid 0.02rem  #4283F7;
    color: #4283F7;
    font-size: 0.8rem;
    border-radius: 1rem;
  }
  .last-child {
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  .class-items {
    flex: 0 0 22%;
    height: 7rem;
    text-align: center;
    /* 边距懒得算，css函数代替 */
    margin-right: calc(12% / 3);
    margin-bottom: calc(12% / 3);
  }
  /* 去除每行尾的多余边距 */
  .class-items:nth-child(4n) {
    margin-right: 0;
  }
  /* 使最后一个元素的边距填满剩余空间 */
  .class-items:last-child {
    margin-right: auto;
  }
}
</style>
